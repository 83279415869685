var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-dialog',{attrs:{"value":_vm.dialog,"disabled":!_vm.canModifyTableRows(_vm.role).allowed,"width":"529","content-class":"v-dialog__form v-dialog__row-expanded-view"},on:{"update:value":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('app-action-btn',_vm._g({attrs:{"access":_vm.canModifyTableRows(_vm.role),"wrapper-class":"mb-0 ml-3 small-p d-flex align-center hover-effect pointer flex-sm-row flex-column","show-tooltip":"","top":""},scopedSlots:_vm._u([{key:"iconWrapper",fn:function(){return [_c('v-icon',[_vm._v(" mdi-delete-outline ")])]},proxy:true}],null,true)},on),[_c('span',[_vm._v(" Trash ")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex align-center"},[_c('ProjectIcon',{attrs:{"color":"#ffffff"}}),_vm._v(" Trash ")],1),_c('v-icon',{attrs:{"color":"#fff"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',{staticClass:"pa-6"},[_vm._l((_vm.deletedRows),function(row){return _c('v-row',{key:row.id,staticClass:"mb-2 restore-row",class:_vm.getClassForLastUndoAction({
          rowId: row.id
        }),attrs:{"no-gutters":"","justify":"space-between"}},[_vm._v(" "+_vm._s(_vm.typeValue(row.type))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.askAndPerform(_vm.restoreRows, _vm.getRestoreText)({
                id: row.id,
                projectId: _vm.projectId,
              })}}},'v-icon',attrs,false),on),[_vm._v(" mdi-backup-restore ")])]}}],null,true)},[_c('span',[_vm._v("Restore the row")])])],1)}),(!_vm.deletedRows.length && !_vm.showSpinner)?_c('p',{staticClass:"medium-p"},[_vm._v(" There are no deleted rows yet. ")]):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }