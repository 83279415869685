<template>
  <app-dialog
    v-model="dialog"
    :value.sync="dialog"
    :disabled="!canModifyTableRows(role).allowed"
    width="529"
    content-class="v-dialog__form v-dialog__row-expanded-view">
    <template #activator="{ on }">
      <app-action-btn
        :access="canModifyTableRows(role)"
        wrapper-class="mb-0 ml-3 small-p d-flex align-center hover-effect pointer flex-sm-row flex-column"
        show-tooltip
        top
        v-on="on">
        <template #iconWrapper>
          <v-icon>
            mdi-delete-outline
          </v-icon>
        </template>
        <span>
          Trash
        </span>
      </app-action-btn>
    </template>
    <v-card>
      <v-card-title>
        <div class="d-flex align-center">
          <ProjectIcon color="#ffffff" />
          Trash
        </div>
        <v-icon
          color="#fff"
          @click="dialog = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pa-6">
        <v-row
          v-for="row in deletedRows"
          :key="row.id"
          class="mb-2 restore-row"
          :class="getClassForLastUndoAction({
            rowId: row.id
          })"
          no-gutters
          justify="space-between">
          {{ typeValue(row.type) }}
          <v-tooltip
            right>
            <template #activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                @click="askAndPerform(restoreRows, getRestoreText)({
                  id: row.id,
                  projectId,
                })">
                mdi-backup-restore
              </v-icon>
            </template>
            <span>Restore the row</span>
          </v-tooltip>
        </v-row>
        <p
          v-if="!deletedRows.length && !showSpinner"
          class="medium-p">
          There are no deleted rows yet.
        </p>
      </v-card-text>
    </v-card>
  </app-dialog>
</template>
<script>
import { askAndPerform } from '@/utils';
import SetClassForLastUndoAction from '@/mixins/SetClassForLastUndoAction';
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';
export default {
  name: 'TableListDeletedRows',
  mixins: [SetClassForLastUndoAction],
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapGetters('ScheduleRows', ['listDeletedRowsForRendering']),
    ...mapState(['showSpinner', 'role']),
    ...mapGetters('UserRoles', ['canModifyTableRows']),
    getRestoreText() {
      return 'Are you sure you want to restore current row?';
    },
    projectId() {
      return this.$route.params.id;
    },
    deletedRows: {
      get() {
        return this.listDeletedRowsForRendering;
      },
      set(val) {
        this.setListDeletedRows(val);
      },
    },
  },
  watch: {
    async dialog(val) {
      if (val) {
        this.getListDeletedRows({
          projectId: this.projectId,
        });
      } else {
        this.deletedRows = [];
      }
    },
  },
  methods: {
    ...mapMutations({
      setListDeletedRows: 'ScheduleRows/setListDeletedRows',
    }),
    ...mapActions({
      restoreRows: 'ScheduleRows/restoreRows',
      getListDeletedRows: 'ScheduleRows/getListDeletedRows',
    }),
    askAndPerform,
    typeValue(val) {
      return (!val || val === '\b' || val === ' ') ? '-----' : val;
    },
  },
};
</script>
<style scoped lang="scss">
  .restore-row {
    &:hover {
      .v-icon {
        color: var(--v-lightBlue-base);
      }
    }
  }
  .v-dialog__container {
    display: initial!important;
  }
</style>
